<template>
  <div class="catalogue-clothing-item" @click.self="close">
    <div class="clothing-item">
      <div ref="wrapper"
           class="wrapper">
        <div ref="header"
             class="header">
          <div class="title">
            {{ good.title }}
          </div>
          <div class="close">
            <base-icon icon="cross" @click="close"></base-icon>
          </div>
        </div>

        <VuePerfectScrollbar ref="content"
                             class="content">
          <catalogue-clothing-item-gallery :clothingItem="good"/>
          <base-button class="add-to-canvas-button"
                       @click="selectGood">{{ $t('studio.catalogue.singleGood.add') }}</base-button>
          <div class="info">
            <div class="description">
              {{ good.description }}
            </div>

            <ul class="meta">
              <li class="characteristic">
                <div class="name">
                  {{ $t('studio.catalogue.singleGood.brand') }}
                </div>
                <div class="value">
                  {{ good.brand.title }}
                </div>
              </li>
              <li class="characteristic">
                <div class="name">
                  {{ $t('studio.catalogue.singleGood.partner') }}
                </div>
                <div class="value">
                  <a v-if="good.partner.is_available"
                     :href="getPartnerById(good.partner_id).partner_url"
                     _target="blank">
                    {{ getPartnerById(good.partner_id).title }}
                  </a>
                  <template v-else>
                    {{ getPartnerById(good.partner_id).title }}
                  </template>
                </div>
              </li>
              <li class="characteristic">
                <div class="name">
                  {{ $t('studio.catalogue.singleGood.price') }}
                </div>
                <div class="value">
                  {{ good.price | currency }} {{ getCurrencyById(good.local_price_currency_id) | currencySymbol }}
                </div>
              </li>
            </ul>
          </div>
        </VuePerfectScrollbar>

        <div class="actions"
             ref="actions">
          <div class="action"
               @mouseover="isLikeIconHovered = true"
               @mouseleave="isLikeIconHovered = false"
               @click="onLike">
            <base-icon-like :active="liked || isLikeIconHovered"/>
            <div class="title">
              {{ $t('studio.catalogue.singleGood.like') }}
            </div>
          </div>
          <div class="action"
               ref="cart"
               @click="toCart">
            <base-icon class="icon" icon="cart"></base-icon>
            <div class="title">
              {{ $t('studio.catalogue.singleGood.toCart') }}
            </div>
          </div>
          <div class="action"
               v-if="good.partner.is_available"
               @click="openPartner">
            <base-icon icon="priceTag"></base-icon>
            <div class="title">
              {{ $t('studio.catalogue.singleGood.buy') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <span ref="addToCartResultMessage">
    {{ addToCartResultText }}
  </span>
  </div>
</template>

<script>
  import bus from '@/bus.js'
  import { authenticated } from '@/services/auth'
  import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  import clothingItemService from '@/services/queries/clothingItemQueries'
  import CatalogueClothingItemGallery from './CatalogueClothingItemGallery.vue'

  export default {
    props: {
      good: {
        required: true,
        type: Object
      }
    },

    components: {
      VuePerfectScrollbar,
      CatalogueClothingItemGallery
    },

    data() {
      return {
        liked: this.good.liked,
        addToCartResultText: '',
        isLikeIconHovered: false
      }
    },

    mounted() {
      this.setContentHeight()
      this.initAddedToCartPopup()
    },

    methods: {
      ...mapActions('cart', {
        addToCart: 'addClothingItem'
      }),

      selectGood() {
        bus.$emit('goodSelected', this.good)
      },

      onLike() {
        if (!authenticated()) {
          this.openAuthModal({ content: 'login' })
          return
        }

        if (!this.liked) {
          clothingItemService.like(this.good.id)
        } else {
          clothingItemService.removeLike(this.good.id)
        }

        this.liked = !this.liked
      },

      toCart() {
        if (this.isItemInCart(this.good)) {
          this.addToCartResultText = this.$t(`studio.catalogue.singleGood.alreadyInCart`)
        } else {
          this.addToCart(this.good)
          this.addToCartResultText = this.$t(`studio.catalogue.singleGood.addedToCart`)
        }

        this.$nextTick(() => {
          this.$refs.cart._tippy.show()
        })
      },

      openPartner() {
        window.open(this.good.partner_side_info.url, '_blank')
      },

      close() {
        this.$emit('close')
      },

      /**
       * TODO: Set content height for scrollbar. Is it the best solution?
       */
      setContentHeight() {
        let headerHeight = window.getComputedStyle(this.$refs.header).height
        let actionsHeight = window.getComputedStyle(this.$refs.actions).height

        this.$refs.content.$el.style.height = `calc(100% - ${headerHeight} - ${actionsHeight})`
      },

      initAddedToCartPopup() {
        tippy(this.$refs.cart, {
          content: this.$refs.addToCartResultMessage,
          animation: 'shift-away',
          placement: 'bottom',
          arrow: true,
          trigger: 'manual',
          onShown(tooltip) {
            setTimeout(() => {
              tooltip.hide()
            }, 800)
          },
        })
      },

      ...mapActions('system', [
        'openAuthModal'
      ])
    },

    computed: {
      ...mapGetters('handbooks', [
        'getCurrencyById',
        'getPartnerById'
      ]),

      ...mapGetters('cart', [
        'isItemInCart'
      ])
    },
  }
</script>

<style lang="scss" scoped>


  .catalogue-clothing-item {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-50%);
    background: #fff;
    border: 1px solid #eee;
    box-shadow: 0 6px 10px 0 hsla(0,0%,94%,.5);
    border-radius: 8px;
    width: 460px;
    height: 90%;
    padding: 20px;
  }

  .clothing-item {
    position: absolute;
    top: 0;
    left: 0;
    background: #fff;

    width: 100%;
    height: 100%;
    padding: 20px;

    .wrapper {
      height: 100%;
    }

    .header {
      display: flex;

      .title {
        font-size: 26px;
        font-weight: bold;
        color: $primary-color;
        letter-spacing: -0.35px;
        line-height: 38px;
      }

      .close {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 40px;
        height: 40px;
        flex-shrink: 0;
        margin-left: auto;
        cursor: pointer;
      }
    }

    .content {
      position: relative;
      padding: 20px 0;
      transform: translate3d(0, 0, 0); // TODO: It is a fix for content jumping on scroll. Is it the best practise?

      .info {
        .description {
          font-size: 14px;
          color: #797979;
          line-height: 23px;
          margin-bottom: 20px;
          max-width: 400px;
        }

        .meta {
          list-style-type: none;
          margin: 0 0 20px 0;
          padding: 0;
        }

        .characteristic {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          .name {
            flex-basis: 100px;
            font-size: 12px;
            color: #B7B7B7;
          }

          .value {
            font-size: 14px;
            color: #797979;

            a {
              font-size: 14px;
            }
          }
        }
      }

      .add-to-canvas-button {
        margin: 10px 0;
      }
    }

    .actions {
      display: flex;
      padding-top: 20px;
      border-top: 1px solid #F4F4F4;

      .action {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        margin-right: 20px;

        .title {
          font-size: 14px;
          color: #797979;
          line-height: 23px;
          margin-top: 8px;
        }

        & > .title {
          transition: all 0.3s;
        }

        &:hover {
          .title {
            color: $primary-color;
          }
        }
      }
    }
  }
</style>
