import ArtBoard from './Artboard/ArtBoard.vue'
import Catalogue from './Catalogue/Catalogue.vue'
import IntroModal from './IntroModal.vue'
import AuthRequiredModal from './AuthRequiredModal.vue'

import bus from '@/bus.js'
import lookService from '@/services/queries/lookQueries'
import { authenticated } from '@/services/auth'

const studioGuideVersion = '2'

export default {
  props: {
    lookId: {
      required: false
    },
    clientId: {
      required: false
    }
  },

  components: {
    ArtBoard,
    Catalogue,
    IntroModal,
    AuthRequiredModal
  },

  data() {
    return {
      artboardDimensions: false,
      artboardWrapperStyle: {},
      previewPicture: null,

      showIntroModal: false,
      showAuthRequiredModal: false,
    }
  },

  created() {
    if (this.clientId) {
      this.setLook({
        ...this.look,
        client_id: this.clientId
      })
    }

    if (this.lookId) {
      this.clearLook()
      this.loadLook(this.lookId)
    }

    bus.$on('goodSelected', (good) => {
      this.onGoodSelected(good)
    })

    bus.$on('canvasStateUpdated', (objects) => {
      this.onCanvasStateUpdated(objects)
    })

    let studioGuide = localStorage.getItem('studioGuide') ?
        JSON.parse(localStorage.getItem('studioGuide')) : false

    if (!studioGuide || !studioGuide.shown ||
        studioGuide.version !== studioGuideVersion) {
      this.showIntroModal = true
      localStorage.setItem('studioGuide', JSON.stringify({
        version: studioGuideVersion,
        shown: true
      }))
    }
  },

  mounted() {
    this.calculateArtboardDimensions()

    if (this.look.items.length > 0) {
      this.initLookFromStorage()
    }
  },

  methods: {
    initLookFromStorage() {
      this.look.items.forEach(item => {
        this.$refs.artboard.addItem(item)
      })
    },

    loadLook(id) {
      lookService.find('me', id).then(look => {
        this.setLook(Object.assign({}, look, { existing: true }))

        look.items.forEach(item => {
          this.$refs.artboard.addItem({
            clothingItemType: this.constants$.artboardPictureTypes.existingClothing,
            clothing_item_id: item.clothing_item_id,
            imageUrl: this.getImageUrl(item.clothing_item),
            geometry: item.geometry,
          })
        })

        if (look.userUploadedPictures) {
          look.userUploadedPictures.forEach(item => {
            this.$refs.artboard.addItem({
              clothingItemType: this.constants$.artboardPictureTypes.userUploadedPicture,
              imageUrl: item.geometry.customImage.find(info => info.title === 'origin').url,
              geometry: item.geometry,
              customData: {
                slices_info: item.geometry.slices_info
              }
            })
          })
        }
      })
    },

    /**
     * Add good on artboard when user clicks "add-to-artboard"
     * button on good preview in catalogue.
     *
     * @param good
     */
    onGoodSelected(good) {
      this.addGoodOnArtboard(good)
    },

    /**
     * Add good on artboard when user drags
     * good preview from catalogue.
     *
     * @param event
     */
    onGoodDrop(event) {
      let good = JSON.parse(event.dataTransfer.getData('good'));
      let coords = this.$refs.artboard.getCoordinates(event)

      this.addGoodOnArtboard(good, coords)
    },

    /**
     * Otherwise drop event won't fire for some reason.
     *
     * @see https://www.w3schools.com/html/html5_draganddrop.asp
     * @param event
     */
    onDragOver(event) {
      event.preventDefault()
    },

    /**
     * Add good on artboard.
     *
     * @param good
     * @param coords
     */
    addGoodOnArtboard(good, coords = null) {
      let subCategory = this.getClothingSubCategoryById(good.clothing_subcategory_id)
      let scaleFactor = subCategory.artboard_scale_factor

      this.$refs.artboard.addItem({
        clothingItemType: this.constants$.artboardPictureTypes.existingClothing,
        clothing_item_id: good.clothing_item_id,
        imageUrl: this.getImageUrl(good),
        scaleFactor,
        geometry: null
      }, coords)
    },

    onPublishClick() {
      if (!authenticated()) {
        this.showAuthRequiredModal = true
        return
      }

      this.goToLookPage()
    },

    /**
     * Go to look page.
     */
    goToLookPage() {
      this.$refs.artboard.deselectAll()

      let look = _.cloneDeep(this.look)

      look.previewPicture = this.$refs.artboard.getCanvas().toDataURL({
        format: 'png'
      })

      this.setLook(look)

      if (look.existing) {
        this.$router.push({
          name: 'publishLook',
          params: {
            id: look.id
          },
          query: {
            userId: look.authorId,
            fromStudio: true
          }
        })
      } else {
        this.$router.push({
          name: 'publishLook',
          query: {
            fromStudio: true
          }
        })
      }
    },

    /**
     * Calculate artboard dimensions.
     *
     * Artboard should be represented as a box with equal width and height.
     * The rest space is kept for categories/subCategories/goods.
     */
    calculateArtboardDimensions() {
      let height = this.$refs.artboardWrapper.clientHeight
      let width  = height

      this.artboardWrapperStyle = {
        'flex-basis': `${width}px`
      }

      this.artboardDimensions = {
        width,
        height
      }
    },

    /**
     * When canvas is updated we get items from artboard and
     * put them in vuex, as well as preview picture.
     *
     * @param items
     */
    onCanvasStateUpdated(items) {
      if (!this.$refs.artboard) {
        return
      }

      let look = _.cloneDeep(this.look)

      look.items = items.filter(
        item => item.clothingItemType === this.constants$.artboardPictureTypes.existingClothing
      )

      look.meta_data = {
        userUploadedPictures: items.filter(
          item => item.clothingItemType === this.constants$.artboardPictureTypes.userUploadedPicture
        )
      }

      look.previewPicture = this.$refs.artboard.getCanvas().toDataURL({
        format: 'png'
      })

      this.setLook(look)
    },

    /**
     * Restart studio.
     */
    restart() {
      this.clearLook()

      this.$refs.artboard.clear()
    },

    // TODO: Remove, use transformed data instead
    getImageUrl(good) {
      return good.images && good.images.length > 0 ?
          good.images[0].slices_info.filter((info) => {
            return info.title === 'middle'
          })[0].url : null
    },

    ...mapActions('studio', [
      'setLook',
      'clearLook'
    ]),

    ...mapActions('system', [
      'openAuthModal'
    ])
  },

  computed: {
    ...mapGetters('studio', {
      look: 'getLook'
    }),

    ...mapGetters('handbooks', [
      'getClothingSubCategoryById'
    ])
  }
}
