import bus from '@/bus.js'
import Breadcrumbs from './Breadcrumbs/Breadcrumbs.vue'
import ClothingItems from './ClothingItems/ClothingItems.vue'
import CatalogueClothingItem from './ClothingItem/CatalogueClothingItem.vue'
import ClothingFilters from '@/components/Shared/ClothingFilters/ClothingFilters.vue'
import FiltersTags from './FiltersTags/FiltersTags.vue'
import CategoryList from './CategoryList/CategoryList.vue'
import GenderSelect from '@/components/Shared/ClothingGenderSelect.vue'

export default {
  components: {
    Breadcrumbs,
    ClothingItems,
    CatalogueClothingItem,
    ClothingFilters,
    FiltersTags,
    CategoryList,
    GenderSelect,
  },

  data() {
    return {
      searchText: '',
      gender: 'female',
      category: null,
      subCategory: null,
      good: null,
      favorite: false,
      showFilters: false
    }
  },

  created() {
    bus.$on('goodOpened', (good) => {
      this.good = good
    })
  },

  methods: {
    removeFilter(item) {
      let stateFilters = _.cloneDeep(this.filters)

        // TODO: Use transformers & transform all different ids to single "id" attribute
      let idMap = {
        partners: 'partner_id'
      }

      let idAttribute = idMap[item.filterType]

      if (item.grouped) {
        stateFilters[item.filterType] = []
      } else {
        stateFilters[item.filterType] = stateFilters[item.filterType].filter(filter => {
          return filter[idAttribute] !== item.filterItem[idAttribute]
        })
      }

      this.$store.commit('studio/setFilters', stateFilters)
    },

    onBreadcrumbSelected(fragment) {
      switch (fragment) {
        case 'gender': {
          this.category = null
          this.subCategory = null
          this.good = null
          this.favorite = null
          break
        }

        case 'category': {
          this.subCategory = null
          this.good = null
          break
        }

        case 'subCategory': {
          this.good = null
          break
        }
      }
    },

    selectCategory(category) {
      this.category = category
    },

    selectSubCategory(subCategory) {
      this.category    = this.getClothingCategoryById(subCategory.clothing_category_id)
      this.subCategory = subCategory
    },

    selectFavorite() {
      this.favorite = true
    },

    onSearchInput: _.debounce(function(value) {
      this.searchText = value
    }, 500),

    loadMore() {
      this.$refs.clothingItems.loadMore()
    },

    closeGood() {
      this.good = null
    },

    ...mapActions('studio', [
      'setFilters'
    ]),
  },

  computed: {
    ...mapGetters('handbooks', [
      'getClothingCategoryById',
      'getClothingSubCategories'
    ]),

    ...mapGetters('studio', {
      filters: 'getFilters',
      quickFilters: 'getArrayForQuickFilters'
    }),

    filters: {
      get() {
        return Object.assign({}, this.$store.state.studio.filters)
      },

      set(value) {
        this.$store.commit('studio/setFilters', value)
      }
    },

    // TODO: Refactor, break into smaller computed properties or something else
    preparedFilters() {
      let result = {}

      let categoryId    = this.category    ? this.category.clothing_category_id : null
      let subCategoryId = this.subCategory ? this.subCategory.clothing_subcategory_id : null

      result.search_text = this.searchText ? this.searchText : null
      result.gender = this.gender
      result.clothing_category_id = categoryId
      result.clothing_subcategory_id = subCategoryId

      if (this.filters.partners.length > 0) {
        result.partner_id = this.filters.partners.map(item => item.partner_id).join(',')
      }

      if (this.favorite) {
        result.is_liked = true
      }

      return result
    },

    subCategories() {
      return this.getClothingSubCategories.filter((subCategory) => {
        return subCategory.clothing_category_id === this.category.clothing_category_id
      })
    },

    showCategories() {
      return !this.category && !this.subCategory && !this.searchText && !this.favorite
    },

    showSubCategories() {
      return this.category && !this.subCategory && !this.searchText && !this.favorite
    },

    clothingItemsTitle() {
      if (this.category && !this.subCategory) {
        return null
      }

      if (this.searchText) {
        return this.$t('studio.catalogue.searchText')
      }

      if (this.subCategory) {
        return this.subCategory.title
      }

      if (this.favorite) {
        return this.$t('studio.catalogue.favorite')
      }

      return null
    }
  },

  watch: {
    gender: function(value) {
      this.category = null
      this.subCategory = null
      this.good = null
    }
  }
}
