import bus from '@/bus.js'

export default {
  props: {
    canReturnBackground: {
      required: true,
      type: Boolean
    }
  },

  created() {
    document.onkeydown = this.onKeyDown
  },

  methods: {
    onKeyDown(event) {
      let eventObject = window.event ? event : event

      if (eventObject.keyCode === this.constants$.keyCodes.backspace || eventObject.keyCode === this.constants$.keyCodes.delete) {
        this.action('remove')
      } else {
        if (eventObject.ctrlKey) {
          switch (eventObject.keyCode) {
            case this.constants$.keyCodes.b:
              if (this.canReturnBackground) {
                this.action('returnBackground')
              } else {
                this.action('removeBackground')
              }
              break;

            case this.constants$.keyCodes.d:
              this.action('clone')
              break

            case this.constants$.keyCodes.m:
              this.action('mirror')
              break

            case this.constants$.keyCodes['[']:
              this.action('sendBackwards')
              break

            case this.constants$.keyCodes[']']:
              this.action('bringForward')
              break
          }

          return
        }

        switch (eventObject.keyCode) {
          case this.constants$.keyCodes.arrowRight: {
            this.action('selectNextItem')
            break
          }

          case this.constants$.keyCodes.arrowLeft: {
            this.action('selectPreviousItem')
            break
          }
        }
      }
    },

    action(name) {
      bus.$emit(name)
    }
  }
}