import lookService from '@/services/queries/lookQueries'
import dataURLtoBlob from 'blueimp-canvas-to-blob'

const boxWidth  = 600
const boxHeight = 600

const API_URL = 'https://scissors.dnato.style'

const drawingToolsSettings = {
  strokeStyle: '#f422ff',
  lineWidth: 15
}

export default {
  props: {
    clothing: {
      required: true,
      type: Object
    }
  },

  data() {
    return {
      canvasHolderStyle: null,
      image: null,
      originalImageSettings: null,

      originalCanvas: null,
      maskCanvas: null,

      drawingCanvasContext: null,
      maskCanvasContext: null,

      drawing: false,
      converting: false,
      saving: false,

      resultImage: null
    }
  },

  mounted() {
    this.loadImage(this.clothing.imageUrl)
    this.setEscapeHandler()
  },

  methods: {
    initContext(canvas, settings) {
      let context = canvas.getContext('2d')
      // TODO: Refactor?
      Object.keys(drawingToolsSettings).forEach((key) => {
        context[key] = drawingToolsSettings[key]
      })
    },

    initCanvas(canvas, width, height) {
      canvas.width = width
      canvas.height = height

      let ctx = canvas.getContext('2d')
      ctx.clearRect(0, 0, ctx.width, ctx.height)
    },

    loadImage(src) {
      let image = new Image()

      image.onload = () => {
        let hRatio = boxWidth  / image.width
        let vRatio = boxHeight / image.height
        let aspectRatio = Math.min(hRatio, vRatio)

        this.originalImageSettings = {
          aspectRatio,
          originalWidth: image.width,
          originalHeight: image.height,
          offsetX: (boxWidth  - image.width  * aspectRatio ) / 2,
          offsetY: (boxHeight - image.height * aspectRatio ) / 2,
          width:  image.width  * aspectRatio,
          height: image.height * aspectRatio
        }

        this.canvasHolderStyle = {
          width:  this.originalImageSettings.width  + 'px',
          height: this.originalImageSettings.height + 'px'
        }

        this.initCanvas(
            this.$refs.originalPictureCanvas,
            this.originalImageSettings.width,
            this.originalImageSettings.height
        )

        this.initCanvas(
            this.$refs.drawingCanvas,
            this.originalImageSettings.width,
            this.originalImageSettings.height
        )

        this.drawingCanvasContext = this.$refs.drawingCanvas.getContext('2d')

        this.maskCanvas = document.createElement('canvas')
        this.initCanvas(
            this.maskCanvas,
            this.originalImageSettings.originalWidth,
            this.originalImageSettings.originalHeight
        )
        this.maskCanvasContext = this.maskCanvas.getContext('2d')

        this.initContext(this.$refs.drawingCanvas, drawingToolsSettings)
        this.initContext(this.maskCanvas, drawingToolsSettings)

        let ctx = this.$refs.originalPictureCanvas.getContext('2d')
        ctx.drawImage(
            image,
            0,
            0,
            this.originalImageSettings.width,
            this.originalImageSettings.height
        )
      }

      image.crossOrigin = 'Anonymous'
      image.src = src

      this.image = image
    },

    reset() {
      this.clearCanvas(this.$refs.drawingCanvas)
      if (this.maskCanvas) {
        this.clearCanvas(this.maskCanvas)
      }
      this.resultImage = null
    },

    onMouseDown(event) {
      this.drawing = true
      this.drawingCanvasContext.beginPath()
      this.maskCanvasContext.beginPath()
    },

    onMouseMove(event) {
      if (!this.drawing) {
        return
      }

      let point = this.getCanvasCoords(this.$refs.drawingCanvas, event)
      let realPoint = this.getRealCoords(point)

      this.drawingCanvasContext.lineTo(point.x, point.y)
      this.drawingCanvasContext.stroke()

      this.maskCanvasContext.lineTo(realPoint.x, realPoint.y)
      this.maskCanvasContext.stroke()
    },

    onMouseUp(event) {
      this.drawing = false
      this.drawingCanvasContext.closePath()
      this.maskCanvasContext.closePath()
    },

    convert() {
      this.converting = true
      let originalBase64 = this.getBase64Image(this.image)
      let maskBase64 = this.maskCanvas.toDataURL('image/png')

      let formData = new FormData

      formData.append('original', dataURLtoBlob(originalBase64))
      formData.append('mask',     dataURLtoBlob(maskBase64))

      axios.post(API_URL, formData, {
        transformRequest: [(data, headers) => {
          delete headers['X-Lamb-Auth-Token']
          delete headers['X-Lamb-Device-Locale']

          return data
        }],
        responseType: 'arraybuffer',
      }).then(response => {
        this.converting = false

        let blob = new Blob([response.data], { type: 'image/png' })

        let image = new Image
        image.src = URL.createObjectURL(blob)

        image.onload = () => {
          this.resultImage = image
        }
      })
    },

    getBase64Image(img) {
      let canvas = document.createElement('canvas')
      canvas.width  = img.width
      canvas.height = img.height

      var ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0)

      return canvas.toDataURL('image/png')
    },

    save() {
      this.saving = true

      let dataUrl = this.getBase64Image(this.resultImage)

      lookService.storeEditedImage(dataUrl).then(response => {
        this.saving = false

        this.$emit('edited', {
          clothing: this.clothing,
          slices: response[0].slices_info
        })

        this.$emit('close')
      })
    },

    getCanvasCoords(canvas, event) {
      let rect = canvas.getBoundingClientRect()

      return {
        x: event.clientX - rect.left,
        y: event.clientY - rect.top
      }
    },

    getRealCoords(coord) {
      return {
        x: coord.x / this.originalImageSettings.aspectRatio,
        y: coord.y / this.originalImageSettings.aspectRatio,
      }
    },

    clearCanvas(canvas) {
      let ctx = canvas.getContext('2d')

      ctx.clearRect(0, 0, canvas.width, canvas.height)
    },

    setEscapeHandler() {
      const escapeHandler = e => {
        if (e.key === 'Escape') {
          this.close()
        }
      }

      document.addEventListener('keydown', escapeHandler)
      this.$once('hook:destroyed', () => {
        document.removeEventListener('keydown', escapeHandler)
      })
    },

    close() {
      this.$emit('close')
    }
  }
}