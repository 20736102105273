<template>
  <ul class="subcategory-list">
    <li v-for="subCategory in subCategories">
      <a href="#" @click.prevent="onSubCategorySelect(subCategory)">
        {{ subCategory.title }}
      </a>
    </li>
  </ul>
</template>

<script>
  export default {
    props: {
      category: {
        required: true
      }
    },

    methods: {
      onSubCategorySelect(subCategory) {
        this.$emit('selectSubCategory', subCategory)
      }
    },

    computed: {
      subCategories() {
        return this.getClothingSubCategoriesByCategoryId(this.category.clothing_category_id)
      },

      ...mapGetters('handbooks', [
        'getClothingSubCategoriesByCategoryId'
      ])
    }
  }
</script>

<style lang="scss">


  .subcategory-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    column-count: 3;
    column-fill: balance;

    & > li {
      padding: 10px;
      break-inside: avoid;

      & > a {
        font-family: CormorantGaramond-SemiBold, serif;
        font-size: 18px;
        color: #282828;

        &:hover {
          color: $primary-color;
        }
      }
    }
  }
</style>
