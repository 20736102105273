<template>
  <li class="category-item"
      @click="onCategorySelect(category)">
    <div class="icon">
      <img :src="category.iconUrl" />
    </div>

    <div class="title" ref="title">{{ category.title }}</div>

    <subcategory-list ref="subCategories"
                      :category="category"
                      @selectSubCategory="onSubCategorySelect"></subcategory-list>

    <div class="arrow">
      <svg class="svg-icon">
        <use xlink:href="#catalogueArrowRight"></use>
      </svg>
    </div>
  </li>
</template>

<script>
  import SubcategoryList from './SubcategoryList.vue'

  export default {
    props: {
      category: {
        required: true
      }
    },

    components: {
      SubcategoryList
    },

    mounted() {
      this.initSubCategoriesMenu()
    },

    methods: {
      onCategorySelect(category) {
        this.$emit('selectCategory', category)
      },

      onSubCategorySelect(subCategory) {
        this.$emit('selectSubCategory', subCategory)
      },

      initSubCategoriesMenu() {
        tippy(this.$refs.title, {
          content: this.$refs.subCategories.$el,
          interactive: true,
          theme: 'light dropdown-menu',
          animation: 'shift-toward',
          arrow: true,
          placement: 'right',
          popperOptions: {
            modifiers: {
              preventOverflow: {
               boundariesElement: 'window'
              }
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .category-item {
    height: 70px;
    display: flex;
    align-items: center;
    padding-right: 20px;

    font-family: CormorantGaramond-SemiBold, sans-serif;
    font-size: 22px;
    color: #282828;
    letter-spacing: 0;
    line-height: 22px;

    cursor: pointer;
    border-bottom: 1px solid #F4F4F4;

    .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 40px;
      height: 40px;

      img {
        width: 100%;
      }
    }

    .title {
      margin-left: 14px;
    }

    .arrow {
      flex-basis: 10px;
      margin-left: auto;
      color: #E5E3E3;
    }
  }
</style>
