import bus from '@/bus.js'

export default {
  created() {
    document.onkeydown = this.onKeyDown
  },

  methods: {
    onKeyDown(event) {
      let eventObject = window.event ? event : event

      if (eventObject.shiftKey && eventObject.ctrlKey && eventObject.keyCode === this.constants$.keyCodes.z) {
        this.action('redo')
      } else {
        if (eventObject.ctrlKey && eventObject.keyCode === this.constants$.keyCodes.z) {
          this.action('undo')
        }
      }
    },

    action(name) {
      bus.$emit(name)
    }
  }
}