import { fabric } from 'fabric'

/**
 * Geometry version is used for consistency over all devices.
 *
 * @type {string}
 */
const geometryVersion = '1.0'

/**
 * Used when removing clothing white background.
 *
 * @type {number}
 */
const whiteDistance = 0.05

fabric.Clothing = fabric.util.createClass(fabric.Image, {
  type: 'clothing',

  initialize: function(element, options) {
    options || (options = { });

    this.callSuper('initialize', element, options)
  },

  toObject: function() {
    return fabric.util.object.extend(this.callSuper('toObject'), {
      type:        this.type,
      geometry:    this.geometry,
      cornerStyle: this.cornerStyle,
      cornerSize:  this.cornerSize,
      transparentCorners: this.transparentCorners,
      cornerColor: this.cornerColor,
      borderColor: this.borderColor,
      _controlsVisibility: this.controlVisibility
    })
  },

  applyGeometry: function() {
    this.set('left',   this.geometry.left   * this.canvas.width)
    this.set('top',    this.geometry.top    * this.canvas.height)
    this.set('scaleX', this.geometry.scaleX * this.canvas.width)
    this.set('scaleY', this.geometry.scaleY * this.canvas.height)

    this.rotate(this.geometry.angle)

    if (this.geometry.mirrored) {
      this.mirror()
    }

    if (this.geometry.backgroundRemoved) {
      this.removeBackground()
    }

    this.setCoords()
  },

  updateGeometry: function() {
    this.geometry = {
      version: geometryVersion,
      left:   this.left   / this.canvas.getWidth(),
      top:    this.top    / this.canvas.getHeight(),
      scaleX: this.scaleX / this.canvas.getWidth(),
      scaleY: this.scaleY / this.canvas.getHeight(),
      ZIndex: this.canvas.getActiveObjects().indexOf(this),
      mirrored: this.geometry ? this.geometry.mirrored : false,
      backgroundRemoved: this.geometry ? this.geometry.backgroundRemoved : true,
      angle: this.geometry ? this.geometry.angle : 0,
      customImage: this.geometry && this.geometry.customImage ? this.geometry.customImage : null
    }
  },

  setCustomImage(slices) {
    let image = document.createElement('img')

    image.onload = () => {
      this.setElement(image)
      this.canvas.renderAll()
    }

    image.crossOrigin = 'Anonymous'
    image.src = slices.find(slice => slice.title === 'origin').url

    this.geometry.customImage = slices
  },

  mirror: function() {
    this.set('flipX', !this.flipX)
    this.geometry.mirrored = !this.geometry.mirrored
  },

  clone: function() {
    return fabric.util.object.clone(this)
  },

  removeBackground: function() {
    let filter = new fabric.Image.filters.RemoveColor({
      distance: whiteDistance,
      color: '#fff'
    })

    this.filters = []
    this.filters.push(filter)
    this.applyFilters()

    this.geometry.backgroundRemoved = true
  },

  returnBackground: function() {
    this.filters = []
    this.applyFilters()

    this.geometry.backgroundRemoved = false
  },

  shift() {
    this.set('top', this.top + 30)
    this.set('left', this.left + 30)
    this.updateGeometry()
  }
})

fabric.Clothing.fromURL = (url, callback, clothingOptions) => {
  fabric.util.loadImage(url, (image) => {
    callback && callback(new fabric.Clothing(image, clothingOptions))
  }, null, clothingOptions && clothingOptions.crossOrigin)
}

fabric.Clothing.fromObject = function(_object, callback) {
  var object = fabric.util.object.clone(_object);
  fabric.util.loadImage(object.src, function(img, error) {
    if (error) {
      callback && callback(null, error);
      return;
    }
    fabric.Image.prototype._initFilters.call(object, object.filters, function(filters) {
      object.filters = filters || [];
      fabric.Image.prototype._initFilters.call(object, [ object.resizeFilter ], function(resizeFilters) {
        object.resizeFilter = resizeFilters[0];
        var image = new fabric.Clothing(img, object);
        callback(image);
      });
    });
  }, null, object.crossOrigin);
}

export default fabric.Clothing