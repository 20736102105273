import bus from '@/bus.js'
import { fabric } from 'fabric'
import ToolBar from './ToolBar/ToolBar.vue'
import UndoRedoBar from './UndoRedoBar/UndoRedoBar.vue'
import EditImageModal from './EditImageModal/EditImageModal.vue'
import UploadButton from './UploadButton/UploadButton.vue'
import lookService from '@/services/queries/lookQueries.js'
import '@/services/fabric/clothing.js'
import { authenticated } from '@/services/auth'

export default {
  props: [
    'dimensions'
  ],

  components: {
    ToolBar,
    UndoRedoBar,
    EditImageModal,
    UploadButton
  },

  data() {
    return {
      loading: false,
      items: [],
      activeClothing: null,

      canvas: null,
      canvasState: null,
      undoStates: [],
      redoStates: [],

      showToolBar: false,
      canReturnBackground: false,

      showEditImageModal: false,
      selectedImageUrl: null,

      selectionStyle: {
        cornerStyle: 'circle',
        cornerSize: 10,
        transparentCorners: false,
        cornerColor: '#6981D3',
        borderColor: '#6981D3'
      },

      controlsVisibility: {
        tl: true,
        mt: false,
        tr: true,
        mr: false,
        br: true,
        mb: false,
        bl: true,
        ml: false
      }
    }
  },

  created() {
    bus.$on('remove', () => {
      this.remove(this.canvas.getActiveObjects())
    })

    bus.$on('removeBackground', () => {
      this.removeBackground(this.canvas.getActiveObjects())
    })

    bus.$on('returnBackground', () => {
      this.returnBackground(this.canvas.getActiveObjects())
    })

    bus.$on('mirror', () => {
      this.mirror(this.canvas.getActiveObjects())
    })

    bus.$on('clone', this.clone)
    bus.$on('bringForward', this.bringForward)
    bus.$on('sendBackwards', this.sendBackwards)
    bus.$on('undo', this.undo)
    bus.$on('redo', this.redo)
    bus.$on('selectNextItem', () => this.selectNearestItem('next'))
    bus.$on('selectPreviousItem', () => this.selectNearestItem('previous'))
    bus.$on('edit', () => {
      if (!authenticated()) {
        event.preventDefault()
        this.openAuthModal()

        return
      }

      this.editImage(this.canvas.getActiveObject())
    })
  },

  mounted() {
    document.body.addEventListener('click', (event) => {
      // TODO: Copy-paste polyfill directly in project instead of using CDN?
      let toolBar = event.target.closest('#toolbar')
      let canvasElement = document.querySelector('#canvas + .upper-canvas')

      if (event.target !== canvasElement && (this.$refs.toolBar && toolBar !== this.$refs.toolBar.$el)) {
        this.canvas.discardActiveObject()
        this.canvas.renderAll()
      }
    })
  },

  methods: {
    /**
     * Init canvas.
     */
    initCanvas() {
      if (this.canvas) {
        this.destroyCanvas()
      }

      this.canvas = new fabric.Canvas('canvas', {
        width: this.dimensions.width,
        height: this.dimensions.height,
        stateful: true
      })

      this.saveCanvasState()

      this.canvas.on({
        'object:modified':   this.onObjectModified,
        'object:moving':     this.onObjectMoving,
        'selection:created': this.onSelectionCreated,
        'selection:updated': this.onSelectionUpdated,
        'selection:cleared': this.onSelectionCleared
      })
    },

    /**
     * Return canvas instance.
     *
     * @returns {fabric.Canvas|*}
     */
    getCanvas() {
      return this.canvas
    },

    /**
     * Add new item on artboard.
     *
     * Item attributes are:
     *  - clothingItemType: any value from artboardPictureTypes
     *  - clothing_item_id: id of clothing item
     *  - imageUrl: url of image which will be added on artboard
     *  - geometry: if geometry is passed it will be immediately applied
     *  - customData: any other data (e.g. original clothing item) which will be kept
     *
     * @param item
     * @param coords
     */
    addItem(item, coords) {
      let imageUrl

      switch (item.clothingItemType) {
        case this.constants$.artboardPictureTypes.existingClothing: {
          if (item.geometry && item.geometry.customImage) {
            imageUrl = item.geometry.customImage.find(slice => slice.title === 'origin').url
          } else {
            imageUrl = item.imageUrl
          }
          break
        }
        case this.constants$.artboardPictureTypes.userUploadedPicture: {
          imageUrl = item.imageUrl
          break
        }
      }

      // @see https://serverfault.com/questions/856904/chrome-s3-cloudfront-no-access-control-allow-origin-header-on-initial-xhr-req
      imageUrl = imageUrl + '?x-request=artboard'

      let options = {
        clothingItemType: item.clothingItemType,
        crossOrigin: 'Anonymous',
        geometry: item.geometry || null,
        clothing_item_id: item.clothing_item_id || null,
        imageUrl,
        customData: item.customData || null
      }

      this.loading = true
      fabric.Clothing.fromURL(imageUrl, clothing => {
        this.loading = false

        this.canvas.add(clothing)
        this.setSelectionStyle(clothing)

        if (clothing.geometry) {
          clothing.applyGeometry()
        } else {
          // Note that the order of following lines is important.
          // At first we scale clothing to appropriate height

          if (clothing.clothingItemType === this.constants$.artboardPictureTypes.existingClothing) {
            clothing.scaleToHeight(item.scaleFactor * this.canvas.height)
          } else {
            clothing.scaleToHeight(this.canvas.height / 4)
          }

          // Then in case of coords are passed, which means user dropped
          // the picture on canvas we center it so that it looks nice
          if (coords) {
            clothing.set('left', coords.x - clothing.width * clothing.scaleX/2)
            clothing.set('top',  coords.y - clothing.height * clothing.scaleY/2)
          } else {
            clothing.center()
          }

          // Then we init default geometry
          clothing.updateGeometry()

          if (clothing.clothingItemType === this.constants$.artboardPictureTypes.userUploadedPicture) {
            clothing.geometry.customImage = item.customData.slices_info
          }

          // And only then remove background since we cannot do this
          // before geometry is set, because removeBackground
          // sets backgroundRemoved property of geometry.
          clothing.removeBackground()

          this.shiftIfNecessary(clothing)
        }

        clothing.setCoords()

        this.items.push(clothing)
        this.saveCanvasState()
      }, options)
    },

    /**
     * Center look.
     */
    centerLook() {
      this.canvas.discardActiveObject()

      let selection = new fabric.ActiveSelection(this.canvas.getObjects(), {
        canvas: this.canvas,
      })

      this.canvas.setActiveObject(selection)
      this.canvas.requestRenderAll()

      selection.center()

      this.canvas.discardActiveObject()

      selection.forEachObject(object => {
        object.updateGeometry()
      })

      this.saveCanvasState()
    },

    /**
     * Upload user picture.
     *
     * @param pictureDataUrl
     */
    uploadUserPicture(pictureDataUrl) {
      lookService.storeUserClothingPicture(pictureDataUrl).then(response => {
        let imageUrl = response[0].slices_info.find(info => info.title === 'origin').url

        let item = {
          clothingItemType: this.constants$.artboardPictureTypes.userUploadedPicture,
          imageUrl,
          customData: {
            slices_info: response[0].slices_info
          }
        }

        this.addItem(item)
      })
    },

    /**
     * Called when user finished editing image using grabcut processing.
     *
     * @param data
     */
    onClothingEdited(data) {
      let index  = this.canvas.getObjects().indexOf(data.clothing)
      let object = this.canvas.getObjects()[index]

      object.setCustomImage(data.slices)

      this.saveCanvasState()
      this.canvas.renderAll()
    },

    /**
     * Set selection style.
     *
     * @param object
     */
    setSelectionStyle(object) {
      Object.assign(object, this.selectionStyle)
      object.setControlsVisibility(this.controlsVisibility)
    },

    /**
     * Destroy canvas.
     *
     * @returns {boolean}
     */
    destroyCanvas() {
      if (!this.canvas) {
        return false
      }

      this.canvas.clear()
      this.canvas.dispose()
    },

    /**
     * If object's geometry equals some of other object
     * geometries and clothing_item_id then we need to
     * shift this object so that user can see it.
     *
     * @param object
     */
    shiftIfNecessary(object) {
      let index = this.canvas.getObjects().indexOf(object)

      let result = this.canvas.getObjects().some((currentObject, currentIndex) => {
        if (currentIndex === index || !currentObject.geometry) {
          return false
        }

        return currentObject.clothing_item_id === object.clothing_item_id &&
            JSON.stringify(currentObject.geometry) === JSON.stringify(object.geometry)
      })

      if (result) {
        object.shift()

        this.shiftIfNecessary(object)
      }
    },

    /**
     * Emit selectionCreated when user selects an image.
     */
    onSelectionCreated(event) {
      this.setSelectionStyle(event.target)

      this.hideInactive()
      this.calculateShowReturnBackground()
      this.showToolBar = true

      this.$emit('selectionCreated')
    },

    /**
     * Emit selectionUpdated when users updates current selection.
     */
    onSelectionUpdated() {
      this.hideInactive()
      this.calculateShowReturnBackground()

      this.$emit('selectionUpdated')
    },

    /**
     * Emit selectionCleared when user removes the selection
     */
    onSelectionCleared(event) {
      this.refreshOpacity()
      this.canvas.renderAll()
      this.recalculateGeometry()
      this.showToolBar = false

      this.$emit('selectionCleared')
    },

    /**
     * Recalculate each object geometry.
     */
    recalculateGeometry() {
      this.canvas.getObjects().forEach((object) => {
        object.updateGeometry()
      })
    },

    /**
     * Restrict object moving withing canvas.
     *
     * @param event
     */
    onObjectMoving(event) {
      let object = event.target

      // if object is too big ignore
      if (object.currentHeight > object.canvas.height ||
          object.currentWidth > object.canvas.width) {
        return
      }

      object.setCoords()

      let left   = object.getBoundingRect().left,
          right  = object.getBoundingRect().left + object.getBoundingRect().width,
          top    = object.getBoundingRect().top,
          bottom = object.getBoundingRect().top + object.getBoundingRect().height

      // top-left corner
      if (left < 0 || top < 0) {
        object.top = Math.max(object.top, object.top - object.getBoundingRect().top)
        object.left = Math.max(object.left, object.left - object.getBoundingRect().left)
      }

      // bottom-right corner
      if (right > object.canvas.width || bottom > object.canvas.height) {
        object.top  = Math.min(object.top, object.canvas.height - object.getBoundingRect().height + object.top - object.getBoundingRect().top)
        object.left = Math.min(object.left, object.canvas.width - object.getBoundingRect().width + object.left - object.getBoundingRect().left)
      }

      // TODO: Is it best solution?
      // @see https://github.com/fabricjs/fabric.js/issues/4511
      // Update coords so that the image does not disappear when moving out of canvas
      object.setCoords()
    },

    /**
     * Emit objectModified & save canvas state when user modifies an image.
     */
    onObjectModified(event) {
      let object = event.target

      if (object.type === 'clothing') {
        event.target.updateGeometry()
      }

      if (object.type === 'activeSelection') {
        object.forEachObject((object) => {
          object.updateGeometry()
        })
      }

      this.saveCanvasState()
      this.calculateShowReturnBackground()
    },

    /**
     * When user selects an image other images should be "hided",
     * meaning that some opacity should be applied to them.
     */
    hideInactive() {
      this.refreshOpacity()

      let activeObject = this.canvas.getActiveObject()
      let activeObjectIndex = this.canvas.getObjects().indexOf(activeObject)

      this.canvas.getObjects().forEach((object) => {
        let index = this.canvas.getObjects().indexOf(object)

        if (index !== activeObjectIndex) {
          object.set('opacity', '0.3')
        }
      })
    },

    /**
     * Refresh opacity of all images.
     */
    refreshOpacity() {
      this.canvas.getObjects().forEach(object => object.set('opacity', '1'))
    },

    /**
     * Undo last action.
     */
    undo() {
      this.redoStates.push(this.canvasState)
      this.canvasState = this.undoStates.pop()

      this.reloadCanvas()
    },

    /**
     * Redo last action.
     */
    redo() {
      this.undoStates.push(this.canvasState)
      this.canvasState = this.redoStates.pop()

      this.reloadCanvas()
    },

    /**
     * Select the nearest canvas object to an active object in specified direction.
     *
     * @param direction
     */
    selectNearestItem(direction) {
      let activeObject = this.canvas.getActiveObject()

      if (!activeObject) {
        return
      }

      let nextObject = this.getNearestItem(direction)

      this.canvas.setActiveObject(nextObject)
      this.canvas.renderAll()
    },

    /**
     * Get the nearest canvas object to the active one in specified direction.
     *
     * @param direction
     * @returns {object}
     */
    getNearestItem(direction) {
      let objects = this.canvas.getObjects()
      let activeObject = this.canvas.getActiveObject()
      let currentIndex = objects.indexOf(activeObject)
      let totalObjectsNumber = objects.length
      let lastIndex = totalObjectsNumber - 1
      let nextIndex

      switch (direction) {
        case 'previous':
          nextIndex = currentIndex === 0 ? lastIndex : currentIndex - 1
          break

        case 'next':
          nextIndex = currentIndex === lastIndex ? 0 : currentIndex + 1
          break
      }

      return objects[nextIndex]
    },

    /**
     * Remove white background.
     */
    removeBackground(objects) {
      let images = Array.isArray(objects) ? objects : [objects]

      images.forEach(object => object.removeBackground())

      this.calculateShowReturnBackground()
      this.saveCanvasState()
      this.canvas.renderAll()
    },

    /**
     * Return white background.
     *
     * @param objects
     */
    returnBackground(objects) {
      let images = Array.isArray(objects) ? objects : [objects]

      images.forEach(object => object.returnBackground())

      this.calculateShowReturnBackground()
      this.saveCanvasState()
      this.canvas.renderAll()
    },

    /**
     * Mirror objects.
     *
     * @param objects
     */
    mirror(objects) {
      let images = Array.isArray(objects) ? objects : [objects]

      images.forEach(object => object.mirror())

      this.saveCanvasState()
      this.canvas.renderAll()
    },

    /**
     * Clone object and add it to artboard.
     */
    clone() {
      let activeObject = this.canvas.getActiveObject()

      if (!activeObject) {
        return
      }

      let object = activeObject.clone()
      object.shift()

      this.canvas.add(object)
      this.canvas.setActiveObject(object)
      this.saveCanvasState()
    },

    /**
     * Remove objects.
     */
    remove(objects) {
      let images = Array.isArray(objects) ? objects : [objects]

      images.forEach((image) => {
        this.canvas.remove(image)
      })

      this.canvas.discardActiveObject()
      this.saveCanvasState()
    },

    /**
     * Bring active object forward (increase "z-index").
     */
    bringForward() {
      this.canvas.getActiveObject().bringForward(true)

      this.saveCanvasState()
    },

    /**
     * Send active object backwards (decrease "z-index").
     */
    sendBackwards() {
      this.canvas.getActiveObject().sendBackwards()

      this.saveCanvasState()
    },

    editImage(object) {
      this.activeClothing = object
      this.showEditImageModal = true
    },

    /**
     * Save canvas state.
     *
     * Refresh redo states, push current state to undo states.
     */
    saveCanvasState() {
      this.redoStates = []

      if (this.canvasState) {
        this.undoStates.push(this.canvasState)
      }

      this.recalculateGeometry()
      this.canvasState = JSON.stringify(this.canvas)

      let result = this.canvas.getObjects().map((object) => {
        return {
          clothingItemType: object.clothingItemType,
          clothing_item_id: object.clothing_item_id,
          imageUrl: object.imageUrl,
          geometry: object.geometry,
          customData: object.customData
        }
      })

      bus.$emit('canvasStateUpdated', result)
    },

    /**
     * Reload canvas.
     */
    reloadCanvas() {
      this.canvas.clear()

      this.canvas.loadFromJSON(this.canvasState, () => {
        this.canvas.renderAll()
      })
    },

    calculateShowReturnBackground() {
      this.canReturnBackground = true

      this.canvas.getActiveObjects().forEach((object) => {
        if (!object.geometry.backgroundRemoved) {
          this.canReturnBackground = false
        }
      })
    },

    /**
     * Clear canvas.
     */
    clear() {
      this.canvas.clear()
      this.saveCanvasState()
    },

    /**
     * Get coordinates.
     *
     * @param event
     * @returns {*|{x, y}}
     */
    getCoordinates(event) {
      return this.canvas.getPointer(event);
    },

    deselectAll() {
      this.canvas.getObjects().forEach((object) => {
        object.set('opacity', '1')
      })

      this.canvas.discardActiveObject().renderAll()
    },

    ...mapActions('system', [
      'openAuthModal'
    ])
  },

  computed: {
    canUndo() {
      return this.undoStates.length > 0
    },

    canRedo() {
      return this.redoStates.length > 0
    },

    isArtboardClear() {
      if (!this.canvasState) {
        return true
      }

      return !JSON.parse(this.canvasState).objects.length
    }
  },

  watch: {
    dimensions: function(val) {
      this.initCanvas()
    }
  }
}
