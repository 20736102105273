import { authenticated } from '@/services/auth'

export default {
  mounted() {
    this.$refs.input.addEventListener('click', (event) => {
      if (!authenticated()) {
        event.preventDefault()
        this.openAuthModal()
      }
    })

    this.$refs.input.addEventListener('change', (event) => {
      let files = event.currentTarget.files

      if (files && files[0]) {
        let reader = new FileReader()

        reader.onload = (event) => {
          this.$emit('fileSelected', event.target.result)
        }

        reader.readAsDataURL(files[0])
      }
    })
  },

  methods: {
    ...mapActions('system', [
      'openAuthModal'
    ])
  }
}
