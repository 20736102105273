<template>
  <base-modal :show="show" @close="$emit('close')" width="500px">
    <div class="heading-accent">{{ $t('studio.introModal.title') }}</div>
    <div style="text-align: justify">
      <p>{{ $t('studio.introModal.guide') }}</p>
    </div>
    <base-button @click="$emit('close')">{{ $t('studio.introModal.close') }}</base-button>
  </base-modal>
</template>

<script>
  export default {
    props: {
      show: {
        required: true,
        default: false
      }
    }
  }
</script>