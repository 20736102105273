<template>
  <base-modal :show="show"
              @close="$emit('close')"
              width="500px">
    <div class="heading heading-accent">
      {{ $t('studio.authRequiredModal.title') }}
    </div>
    <div class="description">
      {{ $t('studio.authRequiredModal.description') }}
    </div>
    <base-button class="full-width"
                 @click="register">
      {{ $t('studio.authRequiredModal.register') }}
    </base-button>
  </base-modal>
</template>

<script>
  import bus from '@/bus'

  export default {
    props: {
      show: {
        required: true,
        default: false
      }
    },

    methods: {
      register() {
        this.$emit('close')
        bus.$emit('registerRequested')
      }
    }
  }
</script>

<style lang="scss" scoped>
.heading {
  text-align: center;
}

.description {
  margin-bottom: 20px;
  text-align: center;
}
</style>