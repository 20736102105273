<template>
  <ul class="category-list">
    <li class="category-item" @click="$emit('selectFavorite')">
      <div class="icon">
        <base-icon icon="like"></base-icon>
      </div>
      <div class="title"
           ref="title">{{ $t('studio.catalogue.categoryList.favorite') }}</div>

      <div class="arrow">
        <svg class="svg-icon">
          <use xlink:href="#catalogueArrowRight"></use>
        </svg>
      </div>
    </li>

    <category-item v-for="category in categories"
                   :category="category"
                   :key="category.clothing_category_id"
                   @selectCategory="onSelectCategory($event)"
                   @selectSubCategory="onSelectSubCategory($event)">
    </category-item>
  </ul>
</template>

<script>
  import CategoryItem from './CategoryItem.vue'

  export default {
    props: {
      gender: {
        required: true
      }
    },

    components: {
      CategoryItem
    },

    methods: {
      onSelectCategory(category) {
        this.$emit('selectCategory', category)
      },

      onSelectSubCategory(subCategory) {
        this.$emit('selectSubCategory', subCategory)
      }
    },

    computed: {
      ...mapGetters('handbooks', [
        'getClothingCategories'
      ]),

      categories() {
        return this.getClothingCategories(this.gender)
      }
    }
  }
</script>

<style lang="scss">
  .category-list {
    list-style-type: none;
    padding: 0;
  }
</style>
