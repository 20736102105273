export default {
  props: {
    filters: {
      required: true,
      type: Array
    }
  },

  data() {
    return {
      internalOptions: _.cloneDeep(this.filters)
    }
  },

  methods: {
    remove(filter) {
      this.$emit('remove', filter)
    }
  }
}