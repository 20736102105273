import bus from '@/bus.js'
import clothingItemService from '@/services/queries/clothingItemQueries'
import ClothingItemCard from '@/components/Shared/ClothingItemCard/ClothingItemCard'

export default {
  props: {
    title: {
      required: false,
      type: String
    },

    filters: {
      required: true,
      type: Object
    }
  },

  components: {
    ClothingItemCard
  },

  data() {
    return {
      loading: false,
      clothingItems: [],

      limit: 100,
      offset: 0,
      total: 0,

      lastPromise: null
    }
  },

  methods: {
    loadClothingItems(append = false) {
      let params = Object.assign(this.filters)

      params.limit  = this.limit
      params.offset = this.offset

      this.loading = true

      const promise = clothingItemService.get(params)
      this.lastPromise = promise

      new Promise((resolve, reject) => {
        promise.then((result) => {
          if (promise == this.lastPromise) {
            this.lastPromise = null
            resolve(result)
          }
        })
      }).then((data) => {
        this.total = data.items.length ?? 0
        bus.$emit('goodsAmountUpdated', this.total)

        if (append) {
          this.clothingItems.push(...data.items)
        } else {
          this.clothingItems = data.items
        }

        this.$emit('updated')

        this.loading = false
      })
    },

    loadMore() {
      if (this.loading) {
        return false
      }

      this.offset = this.offset + this.limit

      if (this.offset > this.total) {
        return
      }

      this.loadClothingItems(true)
    },

    addClothingItemToArtboard(clothingItem) {
      bus.$emit('goodSelected', clothingItem)
    },

    openClothingItem(clothingItem) {
      bus.$emit('goodOpened', clothingItem)
    },

    onDragStart(event, good) {
      event.dataTransfer.setData('good', JSON.stringify(good))
    },

    applyFiltersAfterTimeout: _.debounce(function() {
      this.loadClothingItems()
    }, 500)
  },

  watch: {
    filters: {
      handler(filters, oldFilters) {
        this.clothingItems = []
        this.offset = 0

        if (!oldFilters) {
          this.loadClothingItems()

          return
        }

        this.applyFiltersAfterTimeout()
      },
      immediate: true,
      deep: true
    }
  }
}
